.gradient-blue {
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#002e55+0,005baa+99 */
	background: #002e55; /* Old browsers */
	background: -moz-linear-gradient(
		45deg,
		#002e55 0%,
		#005baa 99%
	); /* FF3.6-15 */
	background: -webkit-linear-gradient(
		45deg,
		#002e55 0%,
		#005baa 99%
	); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(
		45deg,
		#002e55 0%,
		#005baa 99%
	); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#002e55', endColorstr='#005baa',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
.btn.btn-michelin {
	padding: 0 15px;
	border-radius: 7px !important;
}
.btn.btn-michelin img {
	height: 68px;
	float: left;
}
.btn.btn-michelin span.text {
	font-weight: bold;
	margin: 25px;
	float: left;
}
.btn.color-yellow {
	font-weight: bold;
	padding: 7px 45px;
	background-color: #ffe600;
	color: #000000;
	border-color: #ffe600 !important;
	font-size: 20px;
}
.partnercontent {
	font-size: 20px !important;
}
.partnercontent .btn.color-blue {
	color: #ffe600 !important;
	background-color: #095a98 !important;
}
.partnercontent .text-blue {
	color: #095a98 !important;
}
.partnercontent .text-yellow {
	color: #ffe600 !important;
}
.partnercontent .text-white {
	color: #ffffff;
}
.text-black {
	color: #000 !important;
}
.partnercontent .bg-blue {
	background-color: #095a98 !important;
}
.partnercontent .bg-yellow {
	background-color: #ffe600 !important;
}
.partnercontent .bg-white {
	background-color: #ffffff !important;
}
.partnercontent .bg-gray {
	background-color: rgba(0, 0, 0, 0.5) !important;
}
.partnercontent .content-wrapper {
	position: relative;
	width: calc(100% - 160px);
	max-width: 1200px;
	padding: 42px 15px;
	border-radius: 0px !important;
	font-size: 18px;
	margin: 0 auto;
	color: #ffffff;
}
.partnercontent .content-wrapper.radius {
	border-radius: 10px !important;
}
.partnercontent .header-wrapper {
	position: relative;
	background-image: url(../../assets/images/partnercontent/scb/H2.png);
	width: 100%;
	height: 380px;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	padding-top: 55px;
}
.partnercontent .header-wrapper .content-wrapper {
	padding: 42px;
	background-color: rgba(255, 255, 255, 0.8);
	z-index: 9;
}
.partnercontent .section-wrapper {
	position: relative;
	width: 100%;
	height: auto;
}
.partnercontent .content-wrapper .title {
	font-size: 38px !important;
	color: #ffffff;
	font-weight: bold;
	line-height: 1.2;
}
.partnercontent .content-wrapper .title::after {
	content: '';
	width: 60px;
	height: 3px;
	background-color: #fbea57;
	display: block;
}
.partnercontent .content-wrapper .title.text-purple {
	color: #095a98;
}
.partnercontent .nav-wrapper .title::after {
	display: none;
}
.partnercontent .header-wrapper .content-wrapper .detail {
	margin: 25px 0 35px;
}
.partnercontent .line-box {
	margin: 15px 0;
}
.partnercontent .line-box ul {
	padding-left: 0;
}
.partnercontent .line-box li {
	display: inline-block;
	list-style: none;
	background-color: red;
	background-color: #095a98;
	width: 20px;
	height: 10px;
	border-radius: 45px !important;
}
.partnercontent .number-list {
	padding-left: 0;
}
body.partnercontent {
	background-color: #ffffff;
}
.partnercontent .number-list li {
	list-style: none;
	margin: 15px 0;
}
.partnercontent .number-list li span {
	display: inline-table;
	max-width: calc(100% - 50px);
	word-break: break-word;
}
.partnercontent .number-list li i {
	display: inline-block;
	text-align: center;
	width: 30px;
	height: 30px;
	border-radius: 50% !important;
	padding: 4px;
	background-color: #095a98;
	color: #ffffff;
	margin-right: 15px;
}
.partnercontent .number-list.yellow li i {
	background-color: #ffe600;
	color: #000000;
}
.partnercontent .content-box {
	position: absolute;
	right: -1;
	z-index: 9;
	width: calc(100% - 30px);
	height: auto;
	border-radius: 10px !important;
	font-size: 50px;
	margin: 0 auto;
	background-color: #ffffff;
	-webkit-box-shadow: 0px 0px 11px 2px rgba(0, 0, 0, 0.3);
	-moz-box-shadow: 0px 0px 11px 2px rgba(0, 0, 0, 0.3);
	box-shadow: 0px 0px 11px 2px rgba(0, 0, 0, 0.3);
}
.partnercontent .content-box .content-header {
	padding: 15px;
	background-color: #ffe600;
	border-radius: 10px 10px 0 0 !important;
	text-align: center;
	font-size: 18px;
}
.partnercontent .content-box .content-body {
	padding: 15px;
}
.partnercontent .viewpage {
	padding: 0;
	height: 50px;
	width: 100%;
	float: left;
}
.partnercontent .viewpage span.title {
	font-size: 24px !important;
	line-height: 1.2;
	display: block;
	width: calc(100% - 100px);
	white-space: nowrap;
	float: right;
	text-align: right;
	padding-right: 10px;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	height: 60px;
}
.partnercontent .viewpage span.arrow {
	width: 30px;
	float: right;
	margin-right: -25px;
	margin-top: 20px;
}
.partnercontent .viewpage.left,
.partnercontent .viewpage.left span.title {
	float: left;
	text-align: left;
	font-weight: normal;
}
.partnercontent .viewpage.left span.arrow {
	width: 30px;
	float: left;
	margin-right: unset;
	margin-left: -25px;
}
.partnercontent .viewpage span.arrow i {
	font-size: 38px;
	/*position: absolute;
  top: 13px;
  right: 0;*/
}
.partnercontent .box-titel {
	padding: 8px 15px;
	margin-bottom: 25px;
	width: auto;
	display: -webkit-inline-box;
	display: inline-box;
}
.partnercontent .dot-list {
	padding-left: 0;
}
.partnercontent .dot-list li {
	list-style: none;
	color: #ffffff;
	margin-bottom: 16px;
	font-size: 24px;
	line-height: 1;
}
.partnercontent .dot-list li:before {
	background-color: #ffe600;
	border-radius: 50%;
	content: '';
	display: inline-block;
	margin-right: 10px;
	margin-bottom: 2px;
	height: 10px;
	width: 10px;
}
.content-wrapper.PT110 {
	padding-top: 110px;
}
.pl55 {
	padding-left: 55px;
}
.mb110 {
	margin-bottom: 110px;
}
.content-wrapper.PT60 {
	padding-top: 60px;
}

.partnercontent .content-wrapper.list-view .mb30 {
	margin-bottom: 30px;
}

.desktop {
	display: block;
}
.mobile {
	display: none;
}
.keep-right {
	float: right;
}
#pdf-view .slick-prev:before,
#pdf-view .slick-next:before {
	color: #ffffff;
	opacity: 1 !important;
	-webkit-transform: scale(0.7);
	transform: scale(0.7);
}
#pdf-view .slick-next:before {
	content: url(../../assets/images/partnercontent/michelin/arrow-next.png?v=1) !important;
}
#pdf-view .slick-prev:before {
	content: url(../../assets/images/partnercontent/michelin/arrow-prev.png?v=1) !important;
}
@media only screen and (max-width: 992px) {
	.slick-slide {
		height: auto;
	}
	.keep-right {
		float: none;
	}

	/*.page-header .navbar-fixed-top .navbar-toggle {
      display: block;
  }
  .navbar-fixed-top .top_menu.navbar-collapse{
      display: none;
  }*/
}
@media only screen and (max-width: 768px) {
	/*#pdf-view object,#pdf-view iframe{
      height: 500px !important;
  }*/
	.fix1200 {
		width: 100% !important;
		margin: 0 auto;
	}

	.desktop {
		display: none;
	}
	.mobile {
		display: block;
	}
	.content-wrapper.PT60 {
		padding-top: 30px;
	}
	.pl55 {
		padding-left: 15px;
	}
	.mb110 {
		margin-bottom: 0px;
	}
	.partnercontent .content-wrapper.HPB {
		padding-bottom: 0px;
		margin-bottom: 10px;
	}
	.content-wrapper.PT110 {
		padding-top: 0px;
	}
	.partnercontent .content-wrapper {
		position: relative;
		width: calc(100% - 32px);
		padding: 20px 15px;
	}
	.partnercontent .header-wrapper {
		height: 240px;
	}
	.partnercontent .header-wrapper .content-wrapper {
		padding: 20px;
		background-color: #fff;
		z-index: 9;
	}
	.partnercontent .header-wrapper {
		padding-top: 20px;
	}
	.partnercontent .content-wrapper .title {
		font-size: 22px !important;
	}
	.partnercontent .content-wrapper .title::after {
		margin: 0 auto;
	}
	.partnercontent .content-wrapper .title.sub {
		font-size: 16px;
		text-align: center;
	}
	.partnercontent .line-box {
		text-align: center;
	}
	.partnercontent .header-wrapper .content-wrapper .detail {
		margin: 15px 0 15px;
	}
	.partnercontent .number-list li i {
		display: inline-block;
		text-align: center;
		font-size: 15px;
		width: 24px;
		height: 24px;
		border-radius: 50% !important;
		padding: 2px;
		background-color: #095a98;
		color: #ffffff;
		margin-right: 10px;
	}
	.partnercontent .content-box {
		position: initial;
		width: calc(100% + 30px);
		margin-left: -15px;
		margin-right: -15px;
	}

	.partnercontent .content-wrapper.list-view {
		padding: 20px 0;
		text-align: center;
	}
	.partnercontent .content-wrapper.list-view .mb30 {
		margin-bottom: 15px;
	}
	.partnercontent .content-wrapper.list-view .title {
		font-size: 26px !important;
		line-height: 28px;
		margin-top: 15px;
	}
	.partnercontent .content-wrapper.list-view .col-xs-4 {
		padding-right: 0;
	}

	.partnercontent .content-wrapper.list-view .detail p {
		margin-top: 5px;
	}

	.partnercontent .box-titel.bg-yellow.text-white {
		padding: 8px 0;
		margin-bottom: 0;
		width: auto !important;
		display: block;
		background-color: transparent !important;
		color: #ffe600 !important;
		font-size: 20px;
		font-weight: bold;
	}
	.partnercontent .dot-list {
		padding-left: 20px;
		text-align: left;
	}
}
@media only screen and (max-width: 768px) {
	.slick-prev {
		left: -10px !important;
	}
	.slick-next {
		right: 5px !important;
	}
	.page-header .menu-tab {
		padding: 0;
		background: #fff;
		position: relative;
		width: 100%;
	}
	.navbar-toggle .icon-bar {
		display: block;
		width: 22px;
		height: 2px;
		background-color: #666;
		border-radius: 1px;
	}
	.page-header .navbar-nav {
		width: 100% !important;
	}
	.navbar-collapse.in {
		overflow-x: hidden;
	}
	.page-header li.menu a,
	.page-header li.dropdown-text a {
		text-align: left !important;
		padding: 10px 15px 10px 30px !important;
	}
	.page-header li.button {
		padding: 0 0 0 15px;
		padding-top: 5px;
		width: auto;
		display: inline;
		float: left;
	}
	.page-header .navbar-fixed-top .navbar-toggle {
		border: none;
		margin: 10px 15px;
		position: absolute;
		background-color: #eee;
		right: 0;
		top: 5px;
	}
}

.image-slider {
	cursor: pointer;
	outline: none;
}

.image-slider img {
	display: inline-block;
	text-align: center;
}

span.arrow {
	margin-top: 8px !important;
}

.slick-arrow {
	width: 45px;
	height: 70px;
}