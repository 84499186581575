.HeaderContainer{
    position: relative;
    height: 600px;
}
.SearchPriceContainer{
    height: 80px;
}
.headerSlider h1{
    font-weight: bold;
    margin-bottom: 30px;
    font-size: 48px !important;
}
.headerSlider h4{
    font-weight: bold;
    margin-top: 50px;
}
.section-download-app{
    text-align: left;
    margin-top: 15px;
}
.section-download-app .icon-app{
    width: 150px;
    float: left;
    margin-right: 20px;
    border: 1px solid #8a8a8a;
    border-radius: 8px;
    cursor: pointer;
}
.btn-search{
    font-weight: bold;
    height: 80px;
    border-radius: 0 8px 8px 0;
    font-size: 18px;
}
.btn-search i{
    margin-left: 7px;
}
.SearchPriceContainer label{
    color: #000;
    font-weight: bold;
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 0;
}
.SearchPriceContainer input.form-control{
    border: none;
    box-shadow: none;
    background-color: transparent;
    padding: 0 15px 0 0;
}
.SearchPriceContainer .input-group-addon{
    border: none;
    box-shadow: none;
    background-color: transparent;
    color: #D90101;
}

.SearchPriceContainer .pinMap{
    width: calc(50% - 110px);
}
.SearchPriceContainer .pinBtn{
    width: 220px;
}

@media only screen and (max-width: 600px) {
    .pinMap{
        width: 100%;
        border-left: none;
    }
    .pinBtn{
        width: 100%;
    }
    .SearchPriceContainer{
        height: auto;
    }    
    .btn-search{
        font-weight: bold;
        height: 80px;
        border-radius: 8px;
        font-size: 18px;
    }
}
.backgroundCircle {
  background-image: url(/static/media/circle.c5d5a675.svg);
  background-repeat: no-repeat;
  background-position: top -300px left 300px;
  background-size: 100%;
}

.backgroundCircle2 {
  background-image: url(/static/media/circle.c5d5a675.svg);
  background-repeat: no-repeat;
  background-position: top 335px center;
  background-size: 100%;
  max-width: 1500px;
  margin: 0 auto;
}

@media only screen and (max-width: 1388px) {
  .backgroundCircle {
    background-position: top -180px left 235px;
  }
}

@media only screen and (max-width: 1024px) {
  .backgroundCircle {
    background-position: top -130px left 135px;
  }
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.cover-height-home {
  position: relative;
  height: 100vh;
}

@media (max-width: 1024px) {
  .cover-height-home {
    height: calc(100vh - 80px);
  }
}

@media (max-width: 992px) {
  .cover-height-home {
    height: calc(100vh - 80px);
  }
}
.HeaderContainer{
    position: relative;
    height: 600px;
}
.SearchPriceContainer{
    height: 80px;
}
.headerSlider h1{
    font-weight: bold;
    margin-bottom: 30px;
    font-size: 48px !important;
}
.headerSlider h4{
    font-weight: bold;
    margin-top: 50px;
}
.section-download-app{
    text-align: left;
    margin-top: 15px;
}
.section-download-app .icon-app{
    width: 150px;
    float: left;
    margin-right: 20px;
    border: 1px solid #8a8a8a;
    border-radius: 8px;
    cursor: pointer;
}
.btn-search{
    font-weight: bold;
    height: 80px;
    border-radius: 0 8px 8px 0;
    font-size: 18px;
}
.btn-search i{
    margin-left: 7px;
}
.SearchPriceContainer label{
    color: #000;
    font-weight: bold;
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 0;
}
.SearchPriceContainer input.form-control{
    border: none;
    box-shadow: none;
    background-color: transparent;
    padding: 0 15px 0 0;
}
.SearchPriceContainer .input-group-addon{
    border: none;
    box-shadow: none;
    background-color: transparent;
    color: #D90101;
}

.SearchPriceContainer .pinMap{
    width: calc(50% - 110px);
}
.SearchPriceContainer .pinBtn{
    width: 220px;
}

@media only screen and (max-width: 600px) {
    .pinMap{
        width: 100%;
        border-left: none;
    }
    .pinBtn{
        width: 100%;
    }
    .SearchPriceContainer{
        height: auto;
    }    
    .btn-search{
        font-weight: bold;
        height: 80px;
        border-radius: 8px;
        font-size: 18px;
    }
}
.homeMobile.backgroundCircle {
  background-image: url(/static/media/circle.c5d5a675.svg);
  background-repeat: no-repeat;
  background-position: top 60px center;
  background-size: 180%;
}

.homeMobile .backgroundCircle2 {
  background-image: url(/static/media/circle.c5d5a675.svg);
  background-repeat: no-repeat;
  background-position: top 0px left -45px;
  background-size: 180%;
  max-width: 1500px;
  margin: 0 auto;
}

.homeMobile .App {
  text-align: center;
}

.homeMobile .App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.homeMobile .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.homeMobile .App-link {
  color: #61dafb;
}

.homeMobile .img-cover{
  width: 100% !important;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.homeMobile .cover-height {
  position: relative;
  height: 100vh;
}

.wrapper404{
    margin-top: 250px;
    text-align: center;
}
.wrapper404 h1{
   font-size: 28px;
   margin-bottom: 0;
}
.wrapper404 p{
   font-size: 28px;
   font-weight: normal;
}
.wrapper404 button{
    margin-top: 10px;
    width: 150px;
    position: absolute;
    bottom: 50px;
    left: calc(50% - 140px);
}
.img404{
    width: 250px;
    margin: 0 auto 30px;
}

@media only screen and (max-width: 767px) {
    .wrapper404{
        margin-top: 30%;
        text-align: center;
    }
    .img404{
        width: 250px;
        margin: 0 auto 30px;
    }
}
@media (min-width: 1192px) {
  .image-quotes {
    width: 135%;
    position: absolute;
    top: 0;
    right: 0;
  }
}

@media (max-width: 1192px) {
  .image-quotes {
    width: 110%;
    position: absolute;
    top: 0;
    right: 0;
  }
}
@media (max-width: 992px) {
  .image-quotes {
    position: inherit;
  }
} 
.backgroundCirclenotiApp {
  background-image: url(/static/media/circle.c5d5a675.svg);
  background-repeat: no-repeat;
  background-position: top 100px center;
  background-size: 200%;
  text-align: center;
}
.gradient-blue {
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#002e55+0,005baa+99 */
	background: #002e55; /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(
		45deg,
		#002e55 0%,
		#005baa 99%
	); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#002e55', endColorstr='#005baa',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
.btn.btn-michelin {
	padding: 0 15px;
	border-radius: 7px !important;
}
.btn.btn-michelin img {
	height: 68px;
	float: left;
}
.btn.btn-michelin span.text {
	font-weight: bold;
	margin: 25px;
	float: left;
}
.btn.color-yellow {
	font-weight: bold;
	padding: 7px 45px;
	background-color: #ffe600;
	color: #000000;
	border-color: #ffe600 !important;
	font-size: 20px;
}
.partnercontent {
	font-size: 20px !important;
}
.partnercontent .btn.color-blue {
	color: #ffe600 !important;
	background-color: #095a98 !important;
}
.partnercontent .text-blue {
	color: #095a98 !important;
}
.partnercontent .text-yellow {
	color: #ffe600 !important;
}
.partnercontent .text-white {
	color: #ffffff;
}
.text-black {
	color: #000 !important;
}
.partnercontent .bg-blue {
	background-color: #095a98 !important;
}
.partnercontent .bg-yellow {
	background-color: #ffe600 !important;
}
.partnercontent .bg-white {
	background-color: #ffffff !important;
}
.partnercontent .bg-gray {
	background-color: rgba(0, 0, 0, 0.5) !important;
}
.partnercontent .content-wrapper {
	position: relative;
	width: calc(100% - 160px);
	max-width: 1200px;
	padding: 42px 15px;
	border-radius: 0px !important;
	font-size: 18px;
	margin: 0 auto;
	color: #ffffff;
}
.partnercontent .content-wrapper.radius {
	border-radius: 10px !important;
}
.partnercontent .header-wrapper {
	position: relative;
	background-image: url(/static/media/H2.eab7611b.png);
	width: 100%;
	height: 380px;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	padding-top: 55px;
}
.partnercontent .header-wrapper .content-wrapper {
	padding: 42px;
	background-color: rgba(255, 255, 255, 0.8);
	z-index: 9;
}
.partnercontent .section-wrapper {
	position: relative;
	width: 100%;
	height: auto;
}
.partnercontent .content-wrapper .title {
	font-size: 38px !important;
	color: #ffffff;
	font-weight: bold;
	line-height: 1.2;
}
.partnercontent .content-wrapper .title::after {
	content: '';
	width: 60px;
	height: 3px;
	background-color: #fbea57;
	display: block;
}
.partnercontent .content-wrapper .title.text-purple {
	color: #095a98;
}
.partnercontent .nav-wrapper .title::after {
	display: none;
}
.partnercontent .header-wrapper .content-wrapper .detail {
	margin: 25px 0 35px;
}
.partnercontent .line-box {
	margin: 15px 0;
}
.partnercontent .line-box ul {
	padding-left: 0;
}
.partnercontent .line-box li {
	display: inline-block;
	list-style: none;
	background-color: red;
	background-color: #095a98;
	width: 20px;
	height: 10px;
	border-radius: 45px !important;
}
.partnercontent .number-list {
	padding-left: 0;
}
body.partnercontent {
	background-color: #ffffff;
}
.partnercontent .number-list li {
	list-style: none;
	margin: 15px 0;
}
.partnercontent .number-list li span {
	display: inline-table;
	max-width: calc(100% - 50px);
	word-break: break-word;
}
.partnercontent .number-list li i {
	display: inline-block;
	text-align: center;
	width: 30px;
	height: 30px;
	border-radius: 50% !important;
	padding: 4px;
	background-color: #095a98;
	color: #ffffff;
	margin-right: 15px;
}
.partnercontent .number-list.yellow li i {
	background-color: #ffe600;
	color: #000000;
}
.partnercontent .content-box {
	position: absolute;
	right: -1;
	z-index: 9;
	width: calc(100% - 30px);
	height: auto;
	border-radius: 10px !important;
	font-size: 50px;
	margin: 0 auto;
	background-color: #ffffff;
	box-shadow: 0px 0px 11px 2px rgba(0, 0, 0, 0.3);
}
.partnercontent .content-box .content-header {
	padding: 15px;
	background-color: #ffe600;
	border-radius: 10px 10px 0 0 !important;
	text-align: center;
	font-size: 18px;
}
.partnercontent .content-box .content-body {
	padding: 15px;
}
.partnercontent .viewpage {
	padding: 0;
	height: 50px;
	width: 100%;
	float: left;
}
.partnercontent .viewpage span.title {
	font-size: 24px !important;
	line-height: 1.2;
	display: block;
	width: calc(100% - 100px);
	white-space: nowrap;
	float: right;
	text-align: right;
	padding-right: 10px;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	height: 60px;
}
.partnercontent .viewpage span.arrow {
	width: 30px;
	float: right;
	margin-right: -25px;
	margin-top: 20px;
}
.partnercontent .viewpage.left,
.partnercontent .viewpage.left span.title {
	float: left;
	text-align: left;
	font-weight: normal;
}
.partnercontent .viewpage.left span.arrow {
	width: 30px;
	float: left;
	margin-right: unset;
	margin-left: -25px;
}
.partnercontent .viewpage span.arrow i {
	font-size: 38px;
	/*position: absolute;
  top: 13px;
  right: 0;*/
}
.partnercontent .box-titel {
	padding: 8px 15px;
	margin-bottom: 25px;
	width: auto;
	display: -webkit-inline-box;
	display: inline-box;
}
.partnercontent .dot-list {
	padding-left: 0;
}
.partnercontent .dot-list li {
	list-style: none;
	color: #ffffff;
	margin-bottom: 16px;
	font-size: 24px;
	line-height: 1;
}
.partnercontent .dot-list li:before {
	background-color: #ffe600;
	border-radius: 50%;
	content: '';
	display: inline-block;
	margin-right: 10px;
	margin-bottom: 2px;
	height: 10px;
	width: 10px;
}
.content-wrapper.PT110 {
	padding-top: 110px;
}
.pl55 {
	padding-left: 55px;
}
.mb110 {
	margin-bottom: 110px;
}
.content-wrapper.PT60 {
	padding-top: 60px;
}

.partnercontent .content-wrapper.list-view .mb30 {
	margin-bottom: 30px;
}

.desktop {
	display: block;
}
.mobile {
	display: none;
}
.keep-right {
	float: right;
}
#pdf-view .slick-prev:before,
#pdf-view .slick-next:before {
	color: #ffffff;
	opacity: 1 !important;
	-webkit-transform: scale(0.7);
	transform: scale(0.7);
}
#pdf-view .slick-next:before {
	content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAyCAYAAAA5kQlZAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyNpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQwIDc5LjE2MDQ1MSwgMjAxNy8wNS8wNi0wMTowODoyMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChNYWNpbnRvc2gpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjM1RjAxOEY5RkFDQzExRTg4OTk0Qzk1MTREODQ5ODQ3IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjM1RjAxOEZBRkFDQzExRTg4OTk0Qzk1MTREODQ5ODQ3Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MzVGMDE4RjdGQUNDMTFFODg5OTRDOTUxNEQ4NDk4NDciIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MzVGMDE4RjhGQUNDMTFFODg5OTRDOTUxNEQ4NDk4NDciLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz6vhwDZAAABaElEQVR42sTZwW3DMAwFUJXwAD371BGaTeIN0g2aSdwNnA2STdwRevI5I4RCacBtbFimyU8BQgRFAj74qEOQl2EYuvQ7zry+p6BBPE8y+7qu3yODfMj6TcJ8hgRhjgt/Hnj+yF7LYa48X9EVSRzmW8LcZP+IpqJxkRuVZ5ObNoKK/m9wmK8IKprbjKCipS/QVLR2AEVFJYcQVFR60JuKtl7woiLNJQ8q0l60pqK9tlZUZNFoFlRk9fz2UlEyHloq8yBaKpcgGiq3IFup3IOUUkGClFDBgqxRwYPAX83SEIpeaMZfmE0FDJBfSSeNmoSmkUbGVEReRz8JkV/PYQyRRwWiaCdbZ2nYP6OKooA0awmFe0VKKdyCbKVwodFQmFdES2EWZC+FCY0Fxe6KWFGog1hTqGg8KDZXxIuiOIg3RRENgmK1IiiKxSBoilmaCIqninCIk1QCSjFH00VQzAW5TCoR9sfRQ4ABAC7GKyczA2JsAAAAAElFTkSuQmCC) !important;
}
#pdf-view .slick-prev:before {
	content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAyCAYAAAA5kQlZAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyNpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQwIDc5LjE2MDQ1MSwgMjAxNy8wNS8wNi0wMTowODoyMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChNYWNpbnRvc2gpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjM1RjAxOEZERkFDQzExRTg4OTk0Qzk1MTREODQ5ODQ3IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjM1RjAxOEZFRkFDQzExRTg4OTk0Qzk1MTREODQ5ODQ3Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MzVGMDE4RkJGQUNDMTFFODg5OTRDOTUxNEQ4NDk4NDciIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MzVGMDE4RkNGQUNDMTFFODg5OTRDOTUxNEQ4NDk4NDciLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz5bBBLhAAABa0lEQVR42sSZyQ3CMBBFA3IBnHOiBEoIJdBBUglQAXQAJdBBKCAHOuCWMyXwLTlSFJzgZRZLo5EsH178vIyVVdd1hVYry3KDdERsjSLEDumGsLlYK0HUSO0AgXY2CiouiNp1fRCHvu+fRksF2tNBWJjCCKqwM7EZVADgNB5jtFRMxxotFSIgISpYQWJUsIHEqmABSVFBCpKjggwkVwUJCIWKLBBKFckg1CqSQDhURIFwqggG4VYRBCKhYhFEUsUsiLQKL4iGih8QQNw0VEybynPCC4Kvb5AbNxtWTYtZOqnMCGDuSHvEy/UfAdO6XSSrBjAvB3N3XRXiDZhKfI3YraqlyrtYNVTN7hppVYvbV1JV0DkioSr4QONWFXWycqpKOuI5VCXfNdSqsi49SlUkty+FKrIyIFcVaT2So4qlMEpRxVahxapiLRVjVInUrCGqxIrnf6pWGr9JfO8oleeET5Xau2ak6op4fAUYANgNKOHcZPidAAAAAElFTkSuQmCC) !important;
}
@media only screen and (max-width: 992px) {
	.slick-slide {
		height: auto;
	}
	.keep-right {
		float: none;
	}

	/*.page-header .navbar-fixed-top .navbar-toggle {
      display: block;
  }
  .navbar-fixed-top .top_menu.navbar-collapse{
      display: none;
  }*/
}
@media only screen and (max-width: 768px) {
	/*#pdf-view object,#pdf-view iframe{
      height: 500px !important;
  }*/
	.fix1200 {
		width: 100% !important;
		margin: 0 auto;
	}

	.desktop {
		display: none;
	}
	.mobile {
		display: block;
	}
	.content-wrapper.PT60 {
		padding-top: 30px;
	}
	.pl55 {
		padding-left: 15px;
	}
	.mb110 {
		margin-bottom: 0px;
	}
	.partnercontent .content-wrapper.HPB {
		padding-bottom: 0px;
		margin-bottom: 10px;
	}
	.content-wrapper.PT110 {
		padding-top: 0px;
	}
	.partnercontent .content-wrapper {
		position: relative;
		width: calc(100% - 32px);
		padding: 20px 15px;
	}
	.partnercontent .header-wrapper {
		height: 240px;
	}
	.partnercontent .header-wrapper .content-wrapper {
		padding: 20px;
		background-color: #fff;
		z-index: 9;
	}
	.partnercontent .header-wrapper {
		padding-top: 20px;
	}
	.partnercontent .content-wrapper .title {
		font-size: 22px !important;
	}
	.partnercontent .content-wrapper .title::after {
		margin: 0 auto;
	}
	.partnercontent .content-wrapper .title.sub {
		font-size: 16px;
		text-align: center;
	}
	.partnercontent .line-box {
		text-align: center;
	}
	.partnercontent .header-wrapper .content-wrapper .detail {
		margin: 15px 0 15px;
	}
	.partnercontent .number-list li i {
		display: inline-block;
		text-align: center;
		font-size: 15px;
		width: 24px;
		height: 24px;
		border-radius: 50% !important;
		padding: 2px;
		background-color: #095a98;
		color: #ffffff;
		margin-right: 10px;
	}
	.partnercontent .content-box {
		position: initial;
		width: calc(100% + 30px);
		margin-left: -15px;
		margin-right: -15px;
	}

	.partnercontent .content-wrapper.list-view {
		padding: 20px 0;
		text-align: center;
	}
	.partnercontent .content-wrapper.list-view .mb30 {
		margin-bottom: 15px;
	}
	.partnercontent .content-wrapper.list-view .title {
		font-size: 26px !important;
		line-height: 28px;
		margin-top: 15px;
	}
	.partnercontent .content-wrapper.list-view .col-xs-4 {
		padding-right: 0;
	}

	.partnercontent .content-wrapper.list-view .detail p {
		margin-top: 5px;
	}

	.partnercontent .box-titel.bg-yellow.text-white {
		padding: 8px 0;
		margin-bottom: 0;
		width: auto !important;
		display: block;
		background-color: transparent !important;
		color: #ffe600 !important;
		font-size: 20px;
		font-weight: bold;
	}
	.partnercontent .dot-list {
		padding-left: 20px;
		text-align: left;
	}
}
@media only screen and (max-width: 768px) {
	.slick-prev {
		left: -10px !important;
	}
	.slick-next {
		right: 5px !important;
	}
	.page-header .menu-tab {
		padding: 0;
		background: #fff;
		position: relative;
		width: 100%;
	}
	.navbar-toggle .icon-bar {
		display: block;
		width: 22px;
		height: 2px;
		background-color: #666;
		border-radius: 1px;
	}
	.page-header .navbar-nav {
		width: 100% !important;
	}
	.navbar-collapse.in {
		overflow-x: hidden;
	}
	.page-header li.menu a,
	.page-header li.dropdown-text a {
		text-align: left !important;
		padding: 10px 15px 10px 30px !important;
	}
	.page-header li.button {
		padding: 0 0 0 15px;
		padding-top: 5px;
		width: auto;
		display: inline;
		float: left;
	}
	.page-header .navbar-fixed-top .navbar-toggle {
		border: none;
		margin: 10px 15px;
		position: absolute;
		background-color: #eee;
		right: 0;
		top: 5px;
	}
}

.image-slider {
	cursor: pointer;
	outline: none;
}

.image-slider img {
	display: inline-block;
	text-align: center;
}

span.arrow {
	margin-top: 8px !important;
}

.slick-arrow {
	width: 45px;
	height: 70px;
}
.gradient-purple {
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#402343+0,512182+52,4f2084+58,38195d+100 */
	background: #402343; /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(
		45deg,
		#402343 0%,
		#512182 52%,
		#4f2084 58%,
		#38195d 100%
	); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#402343', endColorstr='#38195d',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
.partnercontent-scb {
	font-size: 20px !important;
}
.partnercontent-scb .btn.color-purple {
	color: #ff8000 !important;
	background-color: #4b297f !important;
}
.partnercontent-scb .text-purple {
	color: #4b297f !important;
}
.partnercontent-scb .text-yellow {
	color: #ff8000 !important;
}
.partnercontent-scb .text-white {
	color: #ffffff;
}
.text-black {
	color: #000 !important;
}
.partnercontent-scb .bg-purple {
	background-color: #4b297f !important;
}
.partnercontent-scb .bg-yellow {
	background-color: #ff8000 !important;
}
.partnercontent-scb .bg-white {
	background-color: #ffffff !important;
}
.partnercontent-scb .bg-gray {
	background-color: rgba(0, 0, 0, 0.5) !important;
}
.partnercontent-scb .content-wrapper {
	position: relative;
	width: calc(100% - 160px);
	max-width: 1200px;
	padding: 42px 15px;
	border-radius: 0px !important;
	font-size: 23px;
	margin: 0 auto;
	line-height: 1;
}
.partnercontent-scb .content-wrapper.radius {
	border-radius: 10px !important;
}
.partnercontent-scb .header-wrapper {
	position: relative;
	background-image: url(/static/media/H2.eab7611b.png);
	width: 100%;
	height: 380px;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	padding-top: 55px;
}
.partnercontent-scb .header-wrapper .content-wrapper {
	padding: 42px;
	background-color: rgba(255, 255, 255, 0.8);
	z-index: 9;
}
.partnercontent-scb .section-wrapper {
	position: relative;
	width: 100%;
	height: auto;
}
.partnercontent-scb .content-wrapper .title {
	font-size: 50px !important;
	color: #000000;
	font-weight: bold;
	line-height: 1;
}
.partnercontent-scb .header-wrapper .content-wrapper .detail {
	margin: 25px 0 35px;
}
.partnercontent-scb .line-box {
	margin: 15px 0;
}
.partnercontent-scb .line-box ul {
	padding-left: 0;
}
.partnercontent-scb .line-box li {
	display: inline-block;
	list-style: none;
	background-color: red;
	background-color: #4b297f;
	width: 20px;
	height: 10px;
	border-radius: 45px !important;
}
.partnercontent-scb .number-list {
	padding-left: 0;
}

.partnercontent-scb .number-list li {
	list-style: none;
	margin: 15px 0;
}
.partnercontent-scb .number-list li span {
	display: inline-table;
	max-width: calc(100% - 50px);
	word-break: break-word;
}
.partnercontent-scb .number-list li i {
	display: inline-block;
	text-align: center;
	width: 30px;
	height: 30px;
	border-radius: 50% !important;
	padding: 4px;
	background-color: #4b297f;
	color: #ffffff;
	margin-right: 15px;
}
.partnercontent-scb .number-list.yellow li i {
	background-color: #ff8000;
	color: #000000;
}
.partnercontent-scb .content-box {
	position: absolute;
	right: -1;
	z-index: 9;
	width: calc(100% - 30px);
	height: auto;
	border-radius: 10px !important;
	font-size: 22px;
	margin: 0 auto;
	background-color: #ffffff;
	box-shadow: 0px 0px 11px 2px rgba(0, 0, 0, 0.3);
}
.partnercontent-scb .content-box .content-header {
	padding: 15px;
	background-color: #ff8000;
	border-radius: 10px 10px 0 0 !important;
	text-align: center;
	font-size: 24px;
}
.partnercontent-scb .content-box .content-body {
	padding: 15px;
}
.partnercontent-scb .viewpage {
	padding: 0;
	height: 50px;
	width: 100%;
	float: left;
}
.partnercontent-scb .viewpage span.title {
	font-size: 24px !important;
	line-height: 28px;
	display: block;
	width: calc(100% - 100px);
	white-space: nowrap;
	float: right;
	text-align: right;
	padding-right: 10px;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	height: 60px;
}
.partnercontent-scb .viewpage span.arrow {
	width: 30px;
	float: right;
	margin-right: -25px;
	margin-top: 20px;
}
.partnercontent-scb .viewpage.left,
.partnercontent-scb .viewpage.left span.title {
	float: left;
	text-align: left;
	font-weight: normal;
}
.partnercontent-scb .viewpage.left span.arrow {
	width: 30px;
	float: left;
	margin-right: unset;
	margin-left: -25px;
}
.partnercontent-scb .viewpage span.arrow i {
	font-size: 40px;
	/*position: absolute;
  top: 13px;
  right: 0;*/
}
.partnercontent-scb .box-titel {
	padding: 8px 15px;
	margin-bottom: 25px;
	width: auto;
	display: -webkit-inline-box;
	display: inline-box;
}
.partnercontent-scb .dot-list {
	padding-left: 0;
}
.partnercontent-scb .dot-list li {
	list-style: none;
	color: #ffffff;
	margin-bottom: 20px;
}
.partnercontent-scb .dot-list li:before {
	background-color: #ff8000;
	border-radius: 50%;
	content: '';
	display: inline-block;
	margin-right: 10px;
	margin-bottom: 2px;
	height: 10px;
	width: 10px;
}
.content-wrapper.PT110 {
	padding-top: 110px;
}
.pl55 {
	padding-left: 55px;
}
.mb110 {
	margin-bottom: 110px;
}
.content-wrapper.PT60 {
	padding-top: 60px;
}

.partnercontent-scb .content-wrapper.list-view .mb30 {
	margin-bottom: 30px;
}

.desktop {
	display: block;
}
.mobile {
	display: none;
}
@media only screen and (max-width: 768px) {
	.fix1200 {
		width: 100% !important;
		margin: 0 auto;
	}
	.page-header .menu-tab {
		padding: 0;
		background: #fff;
		position: relative;
		width: 100%;
	}
	.navbar-toggle .icon-bar {
		display: block;
		width: 22px;
		height: 2px;
		background-color: #666;
		border-radius: 1px;
	}
	.page-header .navbar-nav {
		width: 100% !important;
	}
	.navbar-collapse.in {
		overflow-x: hidden;
	}
	.page-header li.menu a,
	.page-header li.dropdown-text a {
		text-align: left !important;
		padding: 10px 15px 10px 30px !important;
	}
	.page-header li.button {
		padding: 0 0 0 15px;
		padding-top: 5px;
		width: auto;
		display: inline;
		float: left;
	}
	.page-header .navbar-fixed-top .navbar-toggle {
		border: none;
		margin: 10px 15px;
		position: absolute;
		background-color: #eee;
		right: 0;
		top: 5px;
	}
	.desktop {
		display: none;
	}
	.mobile {
		display: block;
	}
	.content-wrapper.PT60 {
		padding-top: 30px;
	}
	.pl55 {
		padding-left: 15px;
	}
	.mb110 {
		margin-bottom: 0px;
	}
	.partnercontent-scb .content-wrapper.HPB {
		padding-bottom: 0px;
		margin-bottom: 10px;
	}
	.content-wrapper.PT110 {
		padding-top: 0px;
	}
	.partnercontent-scb .content-wrapper {
		position: relative;
		width: calc(100% - 32px);
		padding: 20px 15px;
	}
	.partnercontent-scb .header-wrapper {
		height: 240px;
	}
	.partnercontent-scb .header-wrapper .content-wrapper {
		padding: 20px;
		background-color: #fff;
		z-index: 9;
	}
	.partnercontent-scb .header-wrapper {
		padding-top: 20px;
	}
	.partnercontent-scb .content-wrapper .title {
		font-size: 30px !important;
	}
	.partnercontent-scb .content-wrapper .title.sub {
		font-size: 20px;
		text-align: center;
	}
	.partnercontent-scb .line-box {
		text-align: center;
	}
	.partnercontent-scb .header-wrapper .content-wrapper .detail {
		margin: 15px 0 15px;
	}
	.partnercontent-scb .number-list li i {
		display: inline-block;
		text-align: center;
		font-size: 15px;
		width: 24px;
		height: 24px;
		border-radius: 50% !important;
		padding: 2px;
		background-color: #4b297f;
		color: #ffffff;
		margin-right: 10px;
	}
	.partnercontent-scb .content-box {
		position: initial;
		width: calc(100% + 30px);
		margin-left: -15px;
		margin-right: -15px;
	}

	.partnercontent-scb .content-wrapper.list-view {
		padding: 20px 0;
		text-align: center;
	}
	.partnercontent-scb .content-wrapper.list-view .mb30 {
		margin-bottom: 15px;
	}
	.partnercontent-scb .content-wrapper.list-view .title {
		font-size: 26px !important;
		line-height: 28px;
		margin-top: 15px;
	}
	.partnercontent-scb .content-wrapper.list-view .col-xs-4 {
		padding-right: 0;
	}

	.partnercontent-scb .content-wrapper.list-view .detail p {
		margin-top: 5px;
	}

	.partnercontent-scb .box-titel.bg-yellow.text-white {
		padding: 8px 0;
		margin-bottom: 0;
		width: auto !important;
		display: block;
		background-color: transparent !important;
		color: #ff8000 !important;
		font-size: 20px;
		font-weight: bold;
	}
	.partnercontent-scb .dot-list {
		padding-left: 20px;
		text-align: left;
	}
}

.HeaderContainer{
    position: relative;
    height: 600px;
}
.SearchPriceContainer{
    height: 80px;
}
.headerSlider h1{
    font-weight: bold;
    margin-bottom: 30px;
    font-size: 48px !important;
}
.headerSlider h4{
    font-weight: bold;
    margin-top: 50px;
}
.section-download-app{
    text-align: left;
    margin-top: 15px;
}
.section-download-app .icon-app{
    width: 150px;
    float: left;
    margin-right: 20px;
    border: 1px solid #8a8a8a;
    border-radius: 8px;
    cursor: pointer;
}
.btn-search{
    font-weight: bold;
    height: 80px;
    border-radius: 0 8px 8px 0;
    font-size: 18px;
}
.btn-search i{
    margin-left: 7px;
}
.SearchPriceContainer label{
    color: #000;
    font-weight: bold;
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 0;
}
.SearchPriceContainer input.form-control{
    border: none;
    box-shadow: none;
    background-color: transparent;
    padding: 0 15px 0 0;
}
.SearchPriceContainer .input-group-addon{
    border: none;
    box-shadow: none;
    background-color: transparent;
    color: #D90101;
}

.SearchPriceContainer .pinMap{
    width: calc(50% - 110px);
}
.SearchPriceContainer .pinBtn{
    width: 220px;
}

@media only screen and (max-width: 600px) {
    .pinMap{
        width: 100%;
        border-left: none;
    }
    .pinBtn{
        width: 100%;
    }
    .SearchPriceContainer{
        height: auto;
    }    
    .btn-search{
        font-weight: bold;
        height: 80px;
        border-radius: 8px;
        font-size: 18px;
    }
}
.backgroundCircle {
  background-image: url(/static/media/circle.c5d5a675.svg);
  background-repeat: no-repeat;
  background-position: top -300px left 300px;
  background-size: 100%;
}

.backgroundCircle2 {
  background-image: url(/static/media/circle.c5d5a675.svg);
  background-repeat: no-repeat;
  background-position: top 335px center;
  background-size: 100%;
  max-width: 1500px;
  margin: 0 auto;
}

@media only screen and (max-width: 1388px) {
  .backgroundCircle {
    background-position: top -180px left 235px;
  }
}

@media only screen and (max-width: 1024px) {
  .backgroundCircle {
    background-position: top -130px left 135px;
  }
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.cover-height {
  position: relative;
  height: calc(100vh - 100px);
}

@media (max-width: 1024px) {
  .cover-height {
    height: calc(100vh - 80px);
  }
}

@media (max-width: 992px) {
  .cover-height {
    height: calc(100vh - 80px);
  }
}
.logo-giztix-container {
  display: block;
  padding: 20px;
}

@media (max-width: 768px) {
  .logo-giztix-container {
    display: none;
    padding: 0px;
  }
}

.image-logo-giztix {
  width: 130px;
}

.zodiac-container {
  padding-top: 0px;
}

@media (max-width: 768px) {
  .zodiac-container {
    padding-top: 30px;
  }
}

.bg-zodiac {
  background-image: url(/static/media/bg01.b36530b1.svg);
  background-repeat: no-repeat;
  background-position: top;
  background-size: 491px;
  height: 100%;
}

@media (max-width: 768px) {
  .bg-zodiac {
    background-size: 358px;
  }
}

.title-container {
  padding-top: 29px;
  padding-bottom: 29px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .title-container {
    padding-top: 23px;
    padding-bottom: 6px;
  }
}

.image-logo-giztix-express {
  width: 120px;
}

@media (max-width: 768px) {
  .image-logo-giztix-express {
    width: 100px;
  }
}

.title-x {
  font-size: 35px;
  color: #fff;
  line-height: 1;
}

@media (max-width: 768px) {
  .title-x {
    font-size: 32px;
  }
}

.title-name {
  font-size: 30px;
  margin-top: -5px;
  color: #fff;
  line-height: 1;
}

@media (max-width: 768px) {
  .title-name {
    font-size: 27px;
  }
}

.swiper {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}

.swiper-slide-active {
  -webkit-transform: translate3d(0px, 0px, 0px) rotateX(0deg) rotateY(0deg) scale(1.1) !important;
          transform: translate3d(0px, 0px, 0px) rotateX(0deg) rotateY(0deg) scale(1.1) !important;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 140px;
}

@media (max-width: 768px) {
  .swiper-slide {
    width: 100px;
  }
}

.swiper-slide img {
  display: block;
  width: 100%;
}

.sub-title-container {
  padding-top: 29px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .sub-title-container {
    padding-top: 6px;
    padding-bottom: 40px;
  }
}

.sub-title-name {
  font-size: 25px;
  color: #fff;
}

.sub-title-zodiac {
  font-size: 30px;
  color: #fff;
  margin-top: 4px;
  font-weight: bold;
}

@media (max-width: 768px) {
  .sub-title-zodiac {
    margin-top: 3px;
  }
}

.promotion-container {
  width: calc(100% - 30%);
  max-width: 700px;
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
}

@media (max-width: 768px) {
  .promotion-container {
    min-width: 330px;
    margin-top: 0px;
  }
}

/* .promotion-container {
  width: calc(100% - 30%);
  max-width: 700px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background-color: #d90101;
  border: 1px solid #d90101;
  border-radius: 5px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.5);
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
}

@media (max-width: 768px) {
  .promotion-container {
    min-width: 330px;
    margin-top: 0px;
  }
}

.promotion-title {
  font-size: 30px;
  color: #fff;
  font-weight: bold;
  margin-top: 5px;
}

@media (max-width: 768px) {
  .promotion-title {
    font-size: 25px;
  }
}

.promotion-code-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid #fff;
  border-radius: 5px;
  margin-bottom: 10px;
  width: 180px;
}

.promotion-code-text {
  color: #d90101;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #fff;
  width: 100px;
  text-align: center;
}

.promotion-code {
  color: #fff;
  text-align: center;
  width: 80px;
  font-weight: bold;
} */

.button-container {
  font-size: 20px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-family: "db_heavent" !important;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 5px;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.5);
  font-weight: bold;
  color: #000;
}

@media (max-width: 768px) {
  .button-container {
    font-size: 16px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.button-container:hover {
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 1);
}

.people-container {
  width: 120px;
}

.content-container {
  width: calc(100% - 25%);
  max-width: 920px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  background-color: #fff;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .content-container {
    width: calc(100% - 10%);
  }
}

.content-header {
  font-size: 26px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}

.content-title {
  text-align: center;
}

.content-subtitle {
  text-indent: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: bold;
}

.content {
  text-indent: 30px;
}

.HeaderContainer{
    position: relative;
    height: 600px;
}
.SearchPriceContainer{
    height: 80px;
}
.headerSlider h1{
    font-weight: bold;
    margin-bottom: 30px;
    font-size: 48px !important;
}
.headerSlider h4{
    font-weight: bold;
    margin-top: 50px;
}
.section-download-app{
    text-align: left;
    margin-top: 15px;
}
.section-download-app .icon-app{
    width: 150px;
    float: left;
    margin-right: 20px;
    border: 1px solid #8a8a8a;
    border-radius: 8px;
    cursor: pointer;
}
.btn-search{
    font-weight: bold;
    height: 80px;
    border-radius: 0 8px 8px 0;
    font-size: 18px;
}
.btn-search i{
    margin-left: 7px;
}
.SearchPriceContainer label{
    color: #000;
    font-weight: bold;
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 0;
}
.SearchPriceContainer input.form-control{
    border: none;
    box-shadow: none;
    background-color: transparent;
    padding: 0 15px 0 0;
}
.SearchPriceContainer .input-group-addon{
    border: none;
    box-shadow: none;
    background-color: transparent;
    color: #D90101;
}

.SearchPriceContainer .pinMap{
    width: calc(50% - 110px);
}
.SearchPriceContainer .pinBtn{
    width: 220px;
}

@media only screen and (max-width: 600px) {
    .pinMap{
        width: 100%;
        border-left: none;
    }
    .pinBtn{
        width: 100%;
    }
    .SearchPriceContainer{
        height: auto;
    }    
    .btn-search{
        font-weight: bold;
        height: 80px;
        border-radius: 8px;
        font-size: 18px;
    }
}
.backgroundCircle {
  background-image: url(/static/media/circle.c5d5a675.svg);
  background-repeat: no-repeat;
  background-position: top -300px left 300px;
  background-size: 100%;
}

.backgroundCircle2 {
  background-image: url(/static/media/circle.c5d5a675.svg);
  background-repeat: no-repeat;
  background-position: top 335px center;
  background-size: 100%;
  max-width: 1500px;
  margin: 0 auto;
}

@media only screen and (max-width: 1388px) {
  .backgroundCircle {
    background-position: top -180px left 235px;
  }
}

@media only screen and (max-width: 1024px) {
  .backgroundCircle {
    background-position: top -130px left 135px;
  }
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.cover-height-career {
  position: relative;
  height: 100vh;
}
.HeaderContainer{
    position: relative;
    height: 600px;
}
.SearchPriceContainer{
    height: 80px;
}
.headerSlider h1{
    font-weight: bold;
    margin-bottom: 30px;
    font-size: 48px !important;
}
.headerSlider h4{
    font-weight: bold;
    margin-top: 50px;
}
.section-download-app{
    text-align: left;
    margin-top: 15px;
}
.section-download-app .icon-app{
    width: 150px;
    float: left;
    margin-right: 20px;
    border: 1px solid #8a8a8a;
    border-radius: 8px;
    cursor: pointer;
}
.btn-search{
    font-weight: bold;
    height: 80px;
    border-radius: 0 8px 8px 0;
    font-size: 18px;
}
.btn-search i{
    margin-left: 7px;
}
.SearchPriceContainer label{
    color: #000;
    font-weight: bold;
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 0;
}
.SearchPriceContainer input.form-control{
    border: none;
    box-shadow: none;
    background-color: transparent;
    padding: 0 15px 0 0;
}
.SearchPriceContainer .input-group-addon{
    border: none;
    box-shadow: none;
    background-color: transparent;
    color: #D90101;
}

.SearchPriceContainer .pinMap{
    width: calc(50% - 110px);
}
.SearchPriceContainer .pinBtn{
    width: 220px;
}

@media only screen and (max-width: 600px) {
    .pinMap{
        width: 100%;
        border-left: none;
    }
    .pinBtn{
        width: 100%;
    }
    .SearchPriceContainer{
        height: auto;
    }    
    .btn-search{
        font-weight: bold;
        height: 80px;
        border-radius: 8px;
        font-size: 18px;
    }
}
.backgroundCircle {
  background-image: url(/static/media/circle.c5d5a675.svg);
  background-repeat: no-repeat;
  background-position: top -300px left 300px;
  background-size: 100%;
}

.backgroundCircle2 {
  background-image: url(/static/media/circle.c5d5a675.svg);
  background-repeat: no-repeat;
  background-position: top 335px center;
  background-size: 100%;
  max-width: 1500px;
  margin: 0 auto;
}

@media only screen and (max-width: 1388px) {
  .backgroundCircle {
    background-position: top -180px left 235px;
  }
}

@media only screen and (max-width: 1024px) {
  .backgroundCircle {
    background-position: top -130px left 135px;
  }
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.cover-height {
  position: relative;
  height: calc(100vh - 100px);
}

@media (max-width: 1024px) {
  .cover-height {
    height: calc(100vh - 80px);
  }
}

@media (max-width: 992px) {
  .cover-height {
    height: calc(100vh - 80px);
  }
}
.HeaderContainer{
    position: relative;
    height: 600px;
}
.SearchPriceContainer{
    height: 80px;
}
.headerSlider h1{
    font-weight: bold;
    margin-bottom: 30px;
    font-size: 48px !important;
}
.headerSlider h4{
    font-weight: bold;
    margin-top: 50px;
}
.section-download-app{
    text-align: left;
    margin-top: 15px;
}
.section-download-app .icon-app{
    width: 150px;
    float: left;
    margin-right: 20px;
    border: 1px solid #8a8a8a;
    border-radius: 8px;
    cursor: pointer;
}
.btn-search{
    font-weight: bold;
    height: 80px;
    border-radius: 0 8px 8px 0;
    font-size: 18px;
}
.btn-search i{
    margin-left: 7px;
}
.SearchPriceContainer label{
    color: #000;
    font-weight: bold;
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 0;
}
.SearchPriceContainer input.form-control{
    border: none;
    box-shadow: none;
    background-color: transparent;
    padding: 0 15px 0 0;
}
.SearchPriceContainer .input-group-addon{
    border: none;
    box-shadow: none;
    background-color: transparent;
    color: #D90101;
}

.SearchPriceContainer .pinMap{
    width: calc(50% - 110px);
}
.SearchPriceContainer .pinBtn{
    width: 220px;
}

@media only screen and (max-width: 600px) {
    .pinMap{
        width: 100%;
        border-left: none;
    }
    .pinBtn{
        width: 100%;
    }
    .SearchPriceContainer{
        height: auto;
    }    
    .btn-search{
        font-weight: bold;
        height: 80px;
        border-radius: 8px;
        font-size: 18px;
    }
}
.backgroundCircle {
  background-image: url(/static/media/circle.c5d5a675.svg);
  background-repeat: no-repeat;
  background-position: top -300px left 300px;
  background-size: 100%;
}

.backgroundCircle2 {
  background-image: url(/static/media/circle.c5d5a675.svg);
  background-repeat: no-repeat;
  background-position: top 335px center;
  background-size: 100%;
  max-width: 1500px;
  margin: 0 auto;
}

@media only screen and (max-width: 1388px) {
  .backgroundCircle {
    background-position: top -180px left 235px;
  }
}

@media only screen and (max-width: 1024px) {
  .backgroundCircle {
    background-position: top -130px left 135px;
  }
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.cover-height {
  position: relative;
  height: calc(100vh - 100px);
}

@media (max-width: 1024px) {
  .cover-height {
    height: calc(100vh - 80px);
  }
}

@media (max-width: 992px) {
  .cover-height {
    height: calc(100vh - 80px);
  }
}

.statusJob {
  text-align: center;
  font-size: 30px;
  font-weight:bold;
  color: #888888;
  margin-bottom: 100px;
  margin-top: 60px;
}

@media (max-width: 768px) {
  .statusJob {
    text-align: center;
    font-size: 30px;
    font-weight:bold;
    color: #888888;
    margin-bottom: 100px;
    margin-top: 60px;
  }
}


@media (max-width: 767px) {
  .statusJob {
    font-size: 20px;
    font-weight: bold;
    color: #888888;
    margin-bottom: 50px;
    margin-top: 30px;
  }
}

.HeaderContainer{
    position: relative;
    height: 600px;
}
.SearchPriceContainer{
    height: 80px;
}
.headerSlider h1{
    font-weight: bold;
    margin-bottom: 30px;
    font-size: 48px !important;
}
.headerSlider h4{
    font-weight: bold;
    margin-top: 50px;
}
.section-download-app{
    text-align: left;
    margin-top: 15px;
}
.section-download-app .icon-app{
    width: 150px;
    float: left;
    margin-right: 20px;
    border: 1px solid #8a8a8a;
    border-radius: 8px;
    cursor: pointer;
}
.btn-search{
    font-weight: bold;
    height: 80px;
    border-radius: 0 8px 8px 0;
    font-size: 18px;
}
.btn-search i{
    margin-left: 7px;
}
.SearchPriceContainer label{
    color: #000;
    font-weight: bold;
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 0;
}
.SearchPriceContainer input.form-control{
    border: none;
    box-shadow: none;
    background-color: transparent;
    padding: 0 15px 0 0;
}
.SearchPriceContainer .input-group-addon{
    border: none;
    box-shadow: none;
    background-color: transparent;
    color: #D90101;
}

.SearchPriceContainer .pinMap{
    width: calc(50% - 110px);
}
.SearchPriceContainer .pinBtn{
    width: 220px;
}

@media only screen and (max-width: 600px) {
    .pinMap{
        width: 100%;
        border-left: none;
    }
    .pinBtn{
        width: 100%;
    }
    .SearchPriceContainer{
        height: auto;
    }    
    .btn-search{
        font-weight: bold;
        height: 80px;
        border-radius: 8px;
        font-size: 18px;
    }
}
.backgroundCircle {
  background-image: url(/static/media/circle.c5d5a675.svg);
  background-repeat: no-repeat;
  background-position: top -300px left 300px;
  background-size: 100%;
}

.backgroundCircle2 {
  background-image: url(/static/media/circle.c5d5a675.svg);
  background-repeat: no-repeat;
  background-position: top 335px center;
  background-size: 100%;
  max-width: 1500px;
  margin: 0 auto;
}

@media only screen and (max-width: 1388px) {
  .backgroundCircle {
    background-position: top -180px left 235px;
  }
}

@media only screen and (max-width: 1024px) {
  .backgroundCircle {
    background-position: top -130px left 135px;
  }
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.cover-height {
  position: relative;
  height: calc(100vh - 100px);
}

@media (max-width: 1024px) {
  .cover-height {
    height: calc(100vh - 80px);
  }
}

@media (max-width: 992px) {
  .cover-height {
    height: calc(100vh - 80px);
  }
}

.statusJob {
  text-align: center;
  font-size: 30px;
  font-weight:bold;
  color: #888888;
  margin-bottom: 100px;
  margin-top: 60px;
}

@media (max-width: 768px) {
  .statusJob {
    text-align: center;
    font-size: 30px;
    font-weight:bold;
    color: #888888;
    margin-bottom: 100px;
    margin-top: 60px;
  }
}


@media (max-width: 767px) {
  .statusJob {
    font-size: 20px;
    font-weight: bold;
    color: #888888;
    margin-bottom: 50px;
    margin-top: 30px;
  }
}
.HeaderContainer{
    position: relative;
    height: 600px;
}
.SearchPriceContainer{
    height: 80px;
}
.headerSlider h1{
    font-weight: bold;
    margin-bottom: 30px;
    font-size: 48px !important;
}
.headerSlider h4{
    font-weight: bold;
    margin-top: 50px;
}
.section-download-app{
    text-align: left;
    margin-top: 15px;
}
.section-download-app .icon-app{
    width: 150px;
    float: left;
    margin-right: 20px;
    border: 1px solid #8a8a8a;
    border-radius: 8px;
    cursor: pointer;
}
.btn-search{
    font-weight: bold;
    height: 80px;
    border-radius: 0 8px 8px 0;
    font-size: 18px;
}
.btn-search i{
    margin-left: 7px;
}
.SearchPriceContainer label{
    color: #000;
    font-weight: bold;
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 0;
}
.SearchPriceContainer input.form-control{
    border: none;
    box-shadow: none;
    background-color: transparent;
    padding: 0 15px 0 0;
}
.SearchPriceContainer .input-group-addon{
    border: none;
    box-shadow: none;
    background-color: transparent;
    color: #D90101;
}

.SearchPriceContainer .pinMap{
    width: calc(50% - 110px);
}
.SearchPriceContainer .pinBtn{
    width: 220px;
}

@media only screen and (max-width: 600px) {
    .pinMap{
        width: 100%;
        border-left: none;
    }
    .pinBtn{
        width: 100%;
    }
    .SearchPriceContainer{
        height: auto;
    }    
    .btn-search{
        font-weight: bold;
        height: 80px;
        border-radius: 8px;
        font-size: 18px;
    }
}
.backgroundCircle {
  background-image: url(/static/media/circle.c5d5a675.svg);
  background-repeat: no-repeat;
  background-position: top -300px left 300px;
  background-size: 100%;
}

.backgroundCircle2 {
  background-image: url(/static/media/circle.c5d5a675.svg);
  background-repeat: no-repeat;
  background-position: top 335px center;
  background-size: 100%;
  max-width: 1500px;
  margin: 0 auto;
}

@media only screen and (max-width: 1388px) {
  .backgroundCircle {
    background-position: top -180px left 235px;
  }
}

@media only screen and (max-width: 1024px) {
  .backgroundCircle {
    background-position: top -130px left 135px;
  }
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.cover-height {
  position: relative;
  height: calc(100vh - 100px);
}

@media (max-width: 1024px) {
  .cover-height {
    height: calc(100vh - 80px);
  }
}

@media (max-width: 992px) {
  .cover-height {
    height: calc(100vh - 80px);
  }
}


.statusJob {
  text-align: center;
  font-size: 30px;
  font-weight:bold;
  color: #888888;
  margin-bottom: 100px;
  margin-top: 60px;
}

@media (max-width: 768px) {
  .statusJob {
    text-align: center;
    font-size: 30px;
    font-weight:bold;
    color: #888888;
    margin-bottom: 100px;
    margin-top: 60px;
  }
}


@media (max-width: 767px) {
  .statusJob {
    font-size: 20px;
    font-weight: bold;
    color: #888888;
    margin-bottom: 50px;
    margin-top: 30px;
  }
}
.HeaderContainer{
    position: relative;
    height: 600px;
}
.SearchPriceContainer{
    height: 80px;
}
.headerSlider h1{
    font-weight: bold;
    margin-bottom: 30px;
    font-size: 48px !important;
}
.headerSlider h4{
    font-weight: bold;
    margin-top: 50px;
}
.section-download-app{
    text-align: left;
    margin-top: 15px;
}
.section-download-app .icon-app{
    width: 150px;
    float: left;
    margin-right: 20px;
    border: 1px solid #8a8a8a;
    border-radius: 8px;
    cursor: pointer;
}
.btn-search{
    font-weight: bold;
    height: 80px;
    border-radius: 0 8px 8px 0;
    font-size: 18px;
}
.btn-search i{
    margin-left: 7px;
}
.SearchPriceContainer label{
    color: #000;
    font-weight: bold;
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 0;
}
.SearchPriceContainer input.form-control{
    border: none;
    box-shadow: none;
    background-color: transparent;
    padding: 0 15px 0 0;
}
.SearchPriceContainer .input-group-addon{
    border: none;
    box-shadow: none;
    background-color: transparent;
    color: #D90101;
}

.SearchPriceContainer .pinMap{
    width: calc(50% - 110px);
}
.SearchPriceContainer .pinBtn{
    width: 220px;
}

@media only screen and (max-width: 600px) {
    .pinMap{
        width: 100%;
        border-left: none;
    }
    .pinBtn{
        width: 100%;
    }
    .SearchPriceContainer{
        height: auto;
    }    
    .btn-search{
        font-weight: bold;
        height: 80px;
        border-radius: 8px;
        font-size: 18px;
    }
}
.backgroundCircle {
  background-image: url(/static/media/circle.c5d5a675.svg);
  background-repeat: no-repeat;
  background-position: top -300px left 300px;
  background-size: 100%;
}

.backgroundCircle2 {
  background-image: url(/static/media/circle.c5d5a675.svg);
  background-repeat: no-repeat;
  background-position: top 335px center;
  background-size: 100%;
  max-width: 1500px;
  margin: 0 auto;
}

@media only screen and (max-width: 1388px) {
  .backgroundCircle {
    background-position: top -180px left 235px;
  }
}

@media only screen and (max-width: 1024px) {
  .backgroundCircle {
    background-position: top -130px left 135px;
  }
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.cover-height {
  position: relative;
  height: calc(100vh - 100px);
}

@media (max-width: 1024px) {
  .cover-height {
    height: calc(100vh - 80px);
  }
}

@media (max-width: 992px) {
  .cover-height {
    height: calc(100vh - 80px);
  }
}
.HeaderContainer{
    position: relative;
    height: 600px;
}
.SearchPriceContainer{
    height: 80px;
}
.headerSlider h1{
    font-weight: bold;
    margin-bottom: 30px;
    font-size: 48px !important;
}
.headerSlider h4{
    font-weight: bold;
    margin-top: 50px;
}
.section-download-app{
    text-align: left;
    margin-top: 15px;
}
.section-download-app .icon-app{
    width: 150px;
    float: left;
    margin-right: 20px;
    border: 1px solid #8a8a8a;
    border-radius: 8px;
    cursor: pointer;
}
.btn-search{
    font-weight: bold;
    height: 80px;
    border-radius: 0 8px 8px 0;
    font-size: 18px;
}
.btn-search i{
    margin-left: 7px;
}
.SearchPriceContainer label{
    color: #000;
    font-weight: bold;
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 0;
}
.SearchPriceContainer input.form-control{
    border: none;
    box-shadow: none;
    background-color: transparent;
    padding: 0 15px 0 0;
}
.SearchPriceContainer .input-group-addon{
    border: none;
    box-shadow: none;
    background-color: transparent;
    color: #D90101;
}

.SearchPriceContainer .pinMap{
    width: calc(50% - 110px);
}
.SearchPriceContainer .pinBtn{
    width: 220px;
}

@media only screen and (max-width: 600px) {
    .pinMap{
        width: 100%;
        border-left: none;
    }
    .pinBtn{
        width: 100%;
    }
    .SearchPriceContainer{
        height: auto;
    }    
    .btn-search{
        font-weight: bold;
        height: 80px;
        border-radius: 8px;
        font-size: 18px;
    }
}
.backgroundCircle {
  background-image: url(/static/media/circle.c5d5a675.svg);
  background-repeat: no-repeat;
  background-position: top -300px left 300px;
  background-size: 100%;
}

.backgroundCircle2 {
  background-image: url(/static/media/circle.c5d5a675.svg);
  background-repeat: no-repeat;
  background-position: top 335px center;
  background-size: 100%;
  max-width: 1500px;
  margin: 0 auto;
}

@media only screen and (max-width: 1388px) {
  .backgroundCircle {
    background-position: top -180px left 235px;
  }
}

@media only screen and (max-width: 1024px) {
  .backgroundCircle {
    background-position: top -130px left 135px;
  }
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.cover-height {
  position: relative;
  height: calc(100vh - 100px);
}

@media (max-width: 1024px) {
  .cover-height {
    height: calc(100vh - 80px);
  }
}

@media (max-width: 992px) {
  .cover-height {
    height: calc(100vh - 80px);
  }
}

.statusJob {
  text-align: center;
  font-size: 30px;
  font-weight:bold;
  color: #888888;
  margin-bottom: 100px;
  margin-top: 60px;
}

@media (max-width: 768px) {
  .statusJob {
    text-align: center;
    font-size: 30px;
    font-weight:bold;
    color: #888888;
    margin-bottom: 100px;
    margin-top: 60px;
  }
}


@media (max-width: 767px) {
  .statusJob {
    font-size: 20px;
    font-weight: bold;
    color: #888888;
    margin-bottom: 50px;
    margin-top: 30px;
  }
}
.HeaderContainer{
    position: relative;
    height: 600px;
}
.SearchPriceContainer{
    height: 80px;
}
.headerSlider h1{
    font-weight: bold;
    margin-bottom: 30px;
    font-size: 48px !important;
}
.headerSlider h4{
    font-weight: bold;
    margin-top: 50px;
}
.section-download-app{
    text-align: left;
    margin-top: 15px;
}
.section-download-app .icon-app{
    width: 150px;
    float: left;
    margin-right: 20px;
    border: 1px solid #8a8a8a;
    border-radius: 8px;
    cursor: pointer;
}
.btn-search{
    font-weight: bold;
    height: 80px;
    border-radius: 0 8px 8px 0;
    font-size: 18px;
}
.btn-search i{
    margin-left: 7px;
}
.SearchPriceContainer label{
    color: #000;
    font-weight: bold;
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 0;
}
.SearchPriceContainer input.form-control{
    border: none;
    box-shadow: none;
    background-color: transparent;
    padding: 0 15px 0 0;
}
.SearchPriceContainer .input-group-addon{
    border: none;
    box-shadow: none;
    background-color: transparent;
    color: #D90101;
}

.SearchPriceContainer .pinMap{
    width: calc(50% - 110px);
}
.SearchPriceContainer .pinBtn{
    width: 220px;
}

@media only screen and (max-width: 600px) {
    .pinMap{
        width: 100%;
        border-left: none;
    }
    .pinBtn{
        width: 100%;
    }
    .SearchPriceContainer{
        height: auto;
    }    
    .btn-search{
        font-weight: bold;
        height: 80px;
        border-radius: 8px;
        font-size: 18px;
    }
}
.backgroundCircle {
  background-image: url(/static/media/circle.c5d5a675.svg);
  background-repeat: no-repeat;
  background-position: top -300px left 300px;
  background-size: 100%;
}

.backgroundCircle2 {
  background-image: url(/static/media/circle.c5d5a675.svg);
  background-repeat: no-repeat;
  background-position: top 335px center;
  background-size: 100%;
  max-width: 1500px;
  margin: 0 auto;
}

@media only screen and (max-width: 1388px) {
  .backgroundCircle {
    background-position: top -180px left 235px;
  }
}

@media only screen and (max-width: 1024px) {
  .backgroundCircle {
    background-position: top -130px left 135px;
  }
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.cover-height {
  position: relative;
  height: calc(100vh - 100px);
}

@media (max-width: 1024px) {
  .cover-height {
    height: calc(100vh - 80px);
  }
}

@media (max-width: 992px) {
  .cover-height {
    height: calc(100vh - 80px);
  }
}
.HeaderContainer{
    position: relative;
    height: 600px;
}
.SearchPriceContainer{
    height: 80px;
}
.headerSlider h1{
    font-weight: bold;
    margin-bottom: 30px;
    font-size: 48px !important;
}
.headerSlider h4{
    font-weight: bold;
    margin-top: 50px;
}
.section-download-app{
    text-align: left;
    margin-top: 15px;
}
.section-download-app .icon-app{
    width: 150px;
    float: left;
    margin-right: 20px;
    border: 1px solid #8a8a8a;
    border-radius: 8px;
    cursor: pointer;
}
.btn-search{
    font-weight: bold;
    height: 80px;
    border-radius: 0 8px 8px 0;
    font-size: 18px;
}
.btn-search i{
    margin-left: 7px;
}
.SearchPriceContainer label{
    color: #000;
    font-weight: bold;
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 0;
}
.SearchPriceContainer input.form-control{
    border: none;
    box-shadow: none;
    background-color: transparent;
    padding: 0 15px 0 0;
}
.SearchPriceContainer .input-group-addon{
    border: none;
    box-shadow: none;
    background-color: transparent;
    color: #D90101;
}

.SearchPriceContainer .pinMap{
    width: calc(50% - 110px);
}
.SearchPriceContainer .pinBtn{
    width: 220px;
}

@media only screen and (max-width: 600px) {
    .pinMap{
        width: 100%;
        border-left: none;
    }
    .pinBtn{
        width: 100%;
    }
    .SearchPriceContainer{
        height: auto;
    }    
    .btn-search{
        font-weight: bold;
        height: 80px;
        border-radius: 8px;
        font-size: 18px;
    }
}
.backgroundCircle {
  background-image: url(/static/media/circle.c5d5a675.svg);
  background-repeat: no-repeat;
  background-position: top -300px left 300px;
  background-size: 100%;
}

.backgroundCircle2 {
  background-image: url(/static/media/circle.c5d5a675.svg);
  background-repeat: no-repeat;
  background-position: top 335px center;
  background-size: 100%;
  max-width: 1500px;
  margin: 0 auto;
}

@media only screen and (max-width: 1388px) {
  .backgroundCircle {
    background-position: top -180px left 235px;
  }
}

@media only screen and (max-width: 1024px) {
  .backgroundCircle {
    background-position: top -130px left 135px;
  }
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.cover-height {
  position: relative;
  height: calc(100vh - 100px);
}

@media (max-width: 1024px) {
  .cover-height {
    height: calc(100vh - 80px);
  }
}

@media (max-width: 992px) {
  .cover-height {
    height: calc(100vh - 80px);
  }
}
.HeaderContainer{
    position: relative;
    height: 600px;
}
.SearchPriceContainer{
    height: 80px;
}
.headerSlider h1{
    font-weight: bold;
    margin-bottom: 30px;
    font-size: 48px !important;
}
.headerSlider h4{
    font-weight: bold;
    margin-top: 50px;
}
.section-download-app{
    text-align: left;
    margin-top: 15px;
}
.section-download-app .icon-app{
    width: 150px;
    float: left;
    margin-right: 20px;
    border: 1px solid #8a8a8a;
    border-radius: 8px;
    cursor: pointer;
}
.btn-search{
    font-weight: bold;
    height: 80px;
    border-radius: 0 8px 8px 0;
    font-size: 18px;
}
.btn-search i{
    margin-left: 7px;
}
.SearchPriceContainer label{
    color: #000;
    font-weight: bold;
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 0;
}
.SearchPriceContainer input.form-control{
    border: none;
    box-shadow: none;
    background-color: transparent;
    padding: 0 15px 0 0;
}
.SearchPriceContainer .input-group-addon{
    border: none;
    box-shadow: none;
    background-color: transparent;
    color: #D90101;
}

.SearchPriceContainer .pinMap{
    width: calc(50% - 110px);
}
.SearchPriceContainer .pinBtn{
    width: 220px;
}

@media only screen and (max-width: 600px) {
    .pinMap{
        width: 100%;
        border-left: none;
    }
    .pinBtn{
        width: 100%;
    }
    .SearchPriceContainer{
        height: auto;
    }    
    .btn-search{
        font-weight: bold;
        height: 80px;
        border-radius: 8px;
        font-size: 18px;
    }
}
.backgroundCircle {
  background-image: url(/static/media/circle.c5d5a675.svg);
  background-repeat: no-repeat;
  background-position: top -300px left 300px;
  background-size: 100%;
}

.backgroundCircle2 {
  background-image: url(/static/media/circle.c5d5a675.svg);
  background-repeat: no-repeat;
  background-position: top 335px center;
  background-size: 100%;
  max-width: 1500px;
  margin: 0 auto;
}

@media only screen and (max-width: 1388px) {
  .backgroundCircle {
    background-position: top -180px left 235px;
  }
}

@media only screen and (max-width: 1024px) {
  .backgroundCircle {
    background-position: top -130px left 135px;
  }
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.cover-height {
  position: relative;
  height: calc(100vh - 100px);
}

@media (max-width: 1024px) {
  .cover-height {
    height: calc(100vh - 80px);
  }
}

@media (max-width: 992px) {
  .cover-height {
    height: calc(100vh - 80px);
  }
}
.HeaderContainer{
    position: relative;
    height: 600px;
}
.SearchPriceContainer{
    height: 80px;
}
.headerSlider h1{
    font-weight: bold;
    margin-bottom: 30px;
    font-size: 48px !important;
}
.headerSlider h4{
    font-weight: bold;
    margin-top: 50px;
}
.section-download-app{
    text-align: left;
    margin-top: 15px;
}
.section-download-app .icon-app{
    width: 150px;
    float: left;
    margin-right: 20px;
    border: 1px solid #8a8a8a;
    border-radius: 8px;
    cursor: pointer;
}
.btn-search{
    font-weight: bold;
    height: 80px;
    border-radius: 0 8px 8px 0;
    font-size: 18px;
}
.btn-search i{
    margin-left: 7px;
}
.SearchPriceContainer label{
    color: #000;
    font-weight: bold;
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 0;
}
.SearchPriceContainer input.form-control{
    border: none;
    box-shadow: none;
    background-color: transparent;
    padding: 0 15px 0 0;
}
.SearchPriceContainer .input-group-addon{
    border: none;
    box-shadow: none;
    background-color: transparent;
    color: #D90101;
}

.SearchPriceContainer .pinMap{
    width: calc(50% - 110px);
}
.SearchPriceContainer .pinBtn{
    width: 220px;
}

@media only screen and (max-width: 600px) {
    .pinMap{
        width: 100%;
        border-left: none;
    }
    .pinBtn{
        width: 100%;
    }
    .SearchPriceContainer{
        height: auto;
    }    
    .btn-search{
        font-weight: bold;
        height: 80px;
        border-radius: 8px;
        font-size: 18px;
    }
}
.backgroundCircle {
  background-image: url(/static/media/circle.c5d5a675.svg);
  background-repeat: no-repeat;
  background-position: top -300px left 300px;
  background-size: 100%;
}

.backgroundCircle2 {
  background-image: url(/static/media/circle.c5d5a675.svg);
  background-repeat: no-repeat;
  background-position: top 335px center;
  background-size: 100%;
  max-width: 1500px;
  margin: 0 auto;
}

@media only screen and (max-width: 1388px) {
  .backgroundCircle {
    background-position: top -180px left 235px;
  }
}

@media only screen and (max-width: 1024px) {
  .backgroundCircle {
    background-position: top -130px left 135px;
  }
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.cover-height {
  position: relative;
  height: calc(100vh - 100px);
}

@media (max-width: 1024px) {
  .cover-height {
    height: calc(100vh - 80px);
  }
}

@media (max-width: 992px) {
  .cover-height {
    height: calc(100vh - 80px);
  }
}
.backgroundTracking {
    background-image: url(/static/media/circle.c5d5a675.svg);
    background-repeat: no-repeat;
    background-position: top 280px center;
    background-size: 100%;
}
.ToolDetailWrapper{
    background-color: #fff;
    z-index: 5;
    left: 0;
    position: absolute;
    box-shadow: 4px 0 10px -4px rgba(0, 0, 0, 0.2);
}
.subToolWrapper::-webkit-scrollbar{
    width: 8px;
}
.subToolWrapper::-webkit-scrollbar-thumb{
    background-color: #ccc;
    border-radius: 8px;
}
.subToolWrapper::-webkit-scrollbar-track{
    background-color: transparent; 
}
#mainShipmentDetail .subDetailWrapper{
    position: relative;
    background-color: #fff;
    float: left;
    width: 420px;
    border-right: 1px solid #E3E3E3;
    box-shadow:none;
    overflow-x: hidden;
    overflow-y: auto;
}
#mainShipmentDetail .subDetailWrapper.open{
    left: 420px;
}
.MapDetailWrapper{
    position:fixed;
    right: 0;
    width: calc(100% - 840px);
    height: calc(100vh - 100px);
}

/* boxPosition */
  .boxPosition{
    margin-top: 20px;
    padding-top: 20px;
  }
  .boxPosition .boxItem {
      position: relative;
      padding-left: 30px;
      border-left: 2px dotted #E3E3E3;
      margin-bottom: 20px;
      margin-left: 20px;
  }
  .boxPosition .boxItem:last-child{
    border-left: 2px dotted transparent;
  }
  .boxPosition .boxItem i {
      position: absolute;
      top: -15px;
      left: -10px;
      font-size: 16px;
      color: #8B8B8B;
  }
  .boxPosition .boxItem .content{
    padding: 0;
    position: relative;
    top: -20px;
  }
  .boxPosition .boxItem .content .title{
    
  }
  .boxPosition .boxItem .content .title.boderBottom{
    border-bottom: 1px solid #E3E3E3;
    padding-bottom: 15px;
    margin-bottom: 10px;
  }
  .boxPosition .boxItem .content .status{
    color: #8B8B8B;
    font-size: .9em;
  }
  .boxPosition .boxItem .content .detail{
  }
  .boxPosition.tracking .boxItem .content .detail{
      margin-top: 15px;
  }
  .boxPosition .boxItem .content .detail .img_wrapper{
      width: 90px;
      float: left;
      text-align: center;
      margin-right: 10px;
  }
  .boxPosition .boxItem .content .detail .img{
      box-sizing: content-box;
      overflow: hidden;
      position: relative;
      width: 90px;
      height: 90px;
      margin-bottom: 10px;
  }
  
  .boxPosition .boxItem .content .detail .img img{
      object-fit: cover;
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      top: -9999px;
      bottom: -9999px;
      left: -9999px;
      right: -9999px;
      margin: auto;
  }
  
  .boxPosition .boxItem.inProgress{
    
  }
  .boxPosition .boxItem.inProgress .title,
  .boxPosition .boxItem.inProgress,
  .boxPosition .boxItem.inProgress .content .status,
  .boxPosition .boxItem.inProgress i {
    color: #D90101;
  }
  
  .boxPosition .boxItem.problem .title,
  .boxPosition .boxItem.problem,
  .boxPosition .boxItem.problem .content .status,
  .boxPosition .boxItem.problem i {
    color: #000000;
  }
  
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.PDPA_wrapper__sLC-q {
  position: fixed;
  left: 0;
  bottom: 0;
  font-size: 14px;
  background-color: rgba(38, 34, 34, 0.95);
  z-index: 999;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 26px;
  color: #ffffff;
  align-items: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.PDPA_content__sk9Yg {
  flex: 1 1;
  margin: 15px;
  display: flex;
  flex-direction: column;
}

.PDPA_link__2RdEq {
  font-size: inherit;
  color: inherit;
  text-decoration: underline;
  display: inline-block;
}

.PDPA_link__2RdEq:hover,
.PDPA_link__2RdEq:active,
.PDPA_link__2RdEq:focus {
  color: #d90101;
  text-decoration: underline;
}

.PDPA_button__38B3A {
  background-color: #d90101;
  border: 2px solid transparent;
  border-radius: 0px;
  box-shadow: none;
  color: white;
  cursor: pointer;
  padding: 6px 12px;
  margin: 15px;
  display: block;
  min-width: 140px;
  font: inherit;
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
}

@media (max-width: 767px) {
  .PDPA_wrapper__sLC-q {
    padding: 0px 16px;
    flex-direction: column;
    align-items: flex-start;
  }

  .PDPA_button__38B3A {
    margin-top: 0;
  }
}

