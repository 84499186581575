.logo-giztix-container {
  display: block;
  padding: 20px;
}

@media (max-width: 768px) {
  .logo-giztix-container {
    display: none;
    padding: 0px;
  }
}

.image-logo-giztix {
  width: 130px;
}

.zodiac-container {
  padding-top: 0px;
}

@media (max-width: 768px) {
  .zodiac-container {
    padding-top: 30px;
  }
}

.bg-zodiac {
  background-image: url("../../assets/images/mutelu2022/bg01.svg");
  background-repeat: no-repeat;
  background-position: top;
  background-size: 491px;
  height: 100%;
}

@media (max-width: 768px) {
  .bg-zodiac {
    background-size: 358px;
  }
}

.title-container {
  padding-top: 29px;
  padding-bottom: 29px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .title-container {
    padding-top: 23px;
    padding-bottom: 6px;
  }
}

.image-logo-giztix-express {
  width: 120px;
}

@media (max-width: 768px) {
  .image-logo-giztix-express {
    width: 100px;
  }
}

.title-x {
  font-size: 35px;
  color: #fff;
  line-height: 1;
}

@media (max-width: 768px) {
  .title-x {
    font-size: 32px;
  }
}

.title-name {
  font-size: 30px;
  margin-top: -5px;
  color: #fff;
  line-height: 1;
}

@media (max-width: 768px) {
  .title-name {
    font-size: 27px;
  }
}

.swiper {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}

.swiper-slide-active {
  transform: translate3d(0px, 0px, 0px) rotateX(0deg) rotateY(0deg) scale(1.1) !important;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 140px;
}

@media (max-width: 768px) {
  .swiper-slide {
    width: 100px;
  }
}

.swiper-slide img {
  display: block;
  width: 100%;
}

.sub-title-container {
  padding-top: 29px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .sub-title-container {
    padding-top: 6px;
    padding-bottom: 40px;
  }
}

.sub-title-name {
  font-size: 25px;
  color: #fff;
}

.sub-title-zodiac {
  font-size: 30px;
  color: #fff;
  margin-top: 4px;
  font-weight: bold;
}

@media (max-width: 768px) {
  .sub-title-zodiac {
    margin-top: 3px;
  }
}

.promotion-container {
  width: calc(100% - 30%);
  max-width: 700px;
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
}

@media (max-width: 768px) {
  .promotion-container {
    min-width: 330px;
    margin-top: 0px;
  }
}

/* .promotion-container {
  width: calc(100% - 30%);
  max-width: 700px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background-color: #d90101;
  border: 1px solid #d90101;
  border-radius: 5px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.5);
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
}

@media (max-width: 768px) {
  .promotion-container {
    min-width: 330px;
    margin-top: 0px;
  }
}

.promotion-title {
  font-size: 30px;
  color: #fff;
  font-weight: bold;
  margin-top: 5px;
}

@media (max-width: 768px) {
  .promotion-title {
    font-size: 25px;
  }
}

.promotion-code-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid #fff;
  border-radius: 5px;
  margin-bottom: 10px;
  width: 180px;
}

.promotion-code-text {
  color: #d90101;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #fff;
  width: 100px;
  text-align: center;
}

.promotion-code {
  color: #fff;
  text-align: center;
  width: 80px;
  font-weight: bold;
} */

.button-container {
  font-size: 20px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-family: "db_heavent" !important;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 5px;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.5);
  font-weight: bold;
  color: #000;
}

@media (max-width: 768px) {
  .button-container {
    font-size: 16px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.button-container:hover {
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 1);
}

.people-container {
  width: 120px;
}

.content-container {
  width: calc(100% - 25%);
  max-width: 920px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  background-color: #fff;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .content-container {
    width: calc(100% - 10%);
  }
}

.content-header {
  font-size: 26px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}

.content-title {
  text-align: center;
}

.content-subtitle {
  text-indent: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: bold;
}

.content {
  text-indent: 30px;
}
