@media (min-width: 1192px) {
  .image-quotes {
    width: 135%;
    position: absolute;
    top: 0;
    right: 0;
  }
}

@media (max-width: 1192px) {
  .image-quotes {
    width: 110%;
    position: absolute;
    top: 0;
    right: 0;
  }
}
@media (max-width: 992px) {
  .image-quotes {
    position: inherit;
  }
} 