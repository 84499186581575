.gradient-purple {
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#402343+0,512182+52,4f2084+58,38195d+100 */
	background: #402343; /* Old browsers */
	background: -moz-linear-gradient(
		45deg,
		#402343 0%,
		#512182 52%,
		#4f2084 58%,
		#38195d 100%
	); /* FF3.6-15 */
	background: -webkit-linear-gradient(
		45deg,
		#402343 0%,
		#512182 52%,
		#4f2084 58%,
		#38195d 100%
	); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(
		45deg,
		#402343 0%,
		#512182 52%,
		#4f2084 58%,
		#38195d 100%
	); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#402343', endColorstr='#38195d',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
.partnercontent-scb {
	font-size: 20px !important;
}
.partnercontent-scb .btn.color-purple {
	color: #ff8000 !important;
	background-color: #4b297f !important;
}
.partnercontent-scb .text-purple {
	color: #4b297f !important;
}
.partnercontent-scb .text-yellow {
	color: #ff8000 !important;
}
.partnercontent-scb .text-white {
	color: #ffffff;
}
.text-black {
	color: #000 !important;
}
.partnercontent-scb .bg-purple {
	background-color: #4b297f !important;
}
.partnercontent-scb .bg-yellow {
	background-color: #ff8000 !important;
}
.partnercontent-scb .bg-white {
	background-color: #ffffff !important;
}
.partnercontent-scb .bg-gray {
	background-color: rgba(0, 0, 0, 0.5) !important;
}
.partnercontent-scb .content-wrapper {
	position: relative;
	width: calc(100% - 160px);
	max-width: 1200px;
	padding: 42px 15px;
	border-radius: 0px !important;
	font-size: 23px;
	margin: 0 auto;
	line-height: 1;
}
.partnercontent-scb .content-wrapper.radius {
	border-radius: 10px !important;
}
.partnercontent-scb .header-wrapper {
	position: relative;
	background-image: url('../../assets/images/partnercontent/scb/H2.png');
	width: 100%;
	height: 380px;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	padding-top: 55px;
}
.partnercontent-scb .header-wrapper .content-wrapper {
	padding: 42px;
	background-color: rgba(255, 255, 255, 0.8);
	z-index: 9;
}
.partnercontent-scb .section-wrapper {
	position: relative;
	width: 100%;
	height: auto;
}
.partnercontent-scb .content-wrapper .title {
	font-size: 50px !important;
	color: #000000;
	font-weight: bold;
	line-height: 1;
}
.partnercontent-scb .header-wrapper .content-wrapper .detail {
	margin: 25px 0 35px;
}
.partnercontent-scb .line-box {
	margin: 15px 0;
}
.partnercontent-scb .line-box ul {
	padding-left: 0;
}
.partnercontent-scb .line-box li {
	display: inline-block;
	list-style: none;
	background-color: red;
	background-color: #4b297f;
	width: 20px;
	height: 10px;
	border-radius: 45px !important;
}
.partnercontent-scb .number-list {
	padding-left: 0;
}

.partnercontent-scb .number-list li {
	list-style: none;
	margin: 15px 0;
}
.partnercontent-scb .number-list li span {
	display: inline-table;
	max-width: calc(100% - 50px);
	word-break: break-word;
}
.partnercontent-scb .number-list li i {
	display: inline-block;
	text-align: center;
	width: 30px;
	height: 30px;
	border-radius: 50% !important;
	padding: 4px;
	background-color: #4b297f;
	color: #ffffff;
	margin-right: 15px;
}
.partnercontent-scb .number-list.yellow li i {
	background-color: #ff8000;
	color: #000000;
}
.partnercontent-scb .content-box {
	position: absolute;
	right: -1;
	z-index: 9;
	width: calc(100% - 30px);
	height: auto;
	border-radius: 10px !important;
	font-size: 22px;
	margin: 0 auto;
	background-color: #ffffff;
	-webkit-box-shadow: 0px 0px 11px 2px rgba(0, 0, 0, 0.3);
	-moz-box-shadow: 0px 0px 11px 2px rgba(0, 0, 0, 0.3);
	box-shadow: 0px 0px 11px 2px rgba(0, 0, 0, 0.3);
}
.partnercontent-scb .content-box .content-header {
	padding: 15px;
	background-color: #ff8000;
	border-radius: 10px 10px 0 0 !important;
	text-align: center;
	font-size: 24px;
}
.partnercontent-scb .content-box .content-body {
	padding: 15px;
}
.partnercontent-scb .viewpage {
	padding: 0;
	height: 50px;
	width: 100%;
	float: left;
}
.partnercontent-scb .viewpage span.title {
	font-size: 24px !important;
	line-height: 28px;
	display: block;
	width: calc(100% - 100px);
	white-space: nowrap;
	float: right;
	text-align: right;
	padding-right: 10px;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	height: 60px;
}
.partnercontent-scb .viewpage span.arrow {
	width: 30px;
	float: right;
	margin-right: -25px;
	margin-top: 20px;
}
.partnercontent-scb .viewpage.left,
.partnercontent-scb .viewpage.left span.title {
	float: left;
	text-align: left;
	font-weight: normal;
}
.partnercontent-scb .viewpage.left span.arrow {
	width: 30px;
	float: left;
	margin-right: unset;
	margin-left: -25px;
}
.partnercontent-scb .viewpage span.arrow i {
	font-size: 40px;
	/*position: absolute;
  top: 13px;
  right: 0;*/
}
.partnercontent-scb .box-titel {
	padding: 8px 15px;
	margin-bottom: 25px;
	width: auto;
	display: -webkit-inline-box;
	display: inline-box;
}
.partnercontent-scb .dot-list {
	padding-left: 0;
}
.partnercontent-scb .dot-list li {
	list-style: none;
	color: #ffffff;
	margin-bottom: 20px;
}
.partnercontent-scb .dot-list li:before {
	background-color: #ff8000;
	border-radius: 50%;
	content: '';
	display: inline-block;
	margin-right: 10px;
	margin-bottom: 2px;
	height: 10px;
	width: 10px;
}
.content-wrapper.PT110 {
	padding-top: 110px;
}
.pl55 {
	padding-left: 55px;
}
.mb110 {
	margin-bottom: 110px;
}
.content-wrapper.PT60 {
	padding-top: 60px;
}

.partnercontent-scb .content-wrapper.list-view .mb30 {
	margin-bottom: 30px;
}

.desktop {
	display: block;
}
.mobile {
	display: none;
}
@media only screen and (max-width: 768px) {
	.fix1200 {
		width: 100% !important;
		margin: 0 auto;
	}
	.page-header .menu-tab {
		padding: 0;
		background: #fff;
		position: relative;
		width: 100%;
	}
	.navbar-toggle .icon-bar {
		display: block;
		width: 22px;
		height: 2px;
		background-color: #666;
		border-radius: 1px;
	}
	.page-header .navbar-nav {
		width: 100% !important;
	}
	.navbar-collapse.in {
		overflow-x: hidden;
	}
	.page-header li.menu a,
	.page-header li.dropdown-text a {
		text-align: left !important;
		padding: 10px 15px 10px 30px !important;
	}
	.page-header li.button {
		padding: 0 0 0 15px;
		padding-top: 5px;
		width: auto;
		display: inline;
		float: left;
	}
	.page-header .navbar-fixed-top .navbar-toggle {
		border: none;
		margin: 10px 15px;
		position: absolute;
		background-color: #eee;
		right: 0;
		top: 5px;
	}
	.desktop {
		display: none;
	}
	.mobile {
		display: block;
	}
	.content-wrapper.PT60 {
		padding-top: 30px;
	}
	.pl55 {
		padding-left: 15px;
	}
	.mb110 {
		margin-bottom: 0px;
	}
	.partnercontent-scb .content-wrapper.HPB {
		padding-bottom: 0px;
		margin-bottom: 10px;
	}
	.content-wrapper.PT110 {
		padding-top: 0px;
	}
	.partnercontent-scb .content-wrapper {
		position: relative;
		width: calc(100% - 32px);
		padding: 20px 15px;
	}
	.partnercontent-scb .header-wrapper {
		height: 240px;
	}
	.partnercontent-scb .header-wrapper .content-wrapper {
		padding: 20px;
		background-color: #fff;
		z-index: 9;
	}
	.partnercontent-scb .header-wrapper {
		padding-top: 20px;
	}
	.partnercontent-scb .content-wrapper .title {
		font-size: 30px !important;
	}
	.partnercontent-scb .content-wrapper .title.sub {
		font-size: 20px;
		text-align: center;
	}
	.partnercontent-scb .line-box {
		text-align: center;
	}
	.partnercontent-scb .header-wrapper .content-wrapper .detail {
		margin: 15px 0 15px;
	}
	.partnercontent-scb .number-list li i {
		display: inline-block;
		text-align: center;
		font-size: 15px;
		width: 24px;
		height: 24px;
		border-radius: 50% !important;
		padding: 2px;
		background-color: #4b297f;
		color: #ffffff;
		margin-right: 10px;
	}
	.partnercontent-scb .content-box {
		position: initial;
		width: calc(100% + 30px);
		margin-left: -15px;
		margin-right: -15px;
	}

	.partnercontent-scb .content-wrapper.list-view {
		padding: 20px 0;
		text-align: center;
	}
	.partnercontent-scb .content-wrapper.list-view .mb30 {
		margin-bottom: 15px;
	}
	.partnercontent-scb .content-wrapper.list-view .title {
		font-size: 26px !important;
		line-height: 28px;
		margin-top: 15px;
	}
	.partnercontent-scb .content-wrapper.list-view .col-xs-4 {
		padding-right: 0;
	}

	.partnercontent-scb .content-wrapper.list-view .detail p {
		margin-top: 5px;
	}

	.partnercontent-scb .box-titel.bg-yellow.text-white {
		padding: 8px 0;
		margin-bottom: 0;
		width: auto !important;
		display: block;
		background-color: transparent !important;
		color: #ff8000 !important;
		font-size: 20px;
		font-weight: bold;
	}
	.partnercontent-scb .dot-list {
		padding-left: 20px;
		text-align: left;
	}
}
