.backgroundCircle {
  background-image: url('../../../../../../assets/images/homepage/cover/circle.svg');
  background-repeat: no-repeat;
  background-position: top -300px left 300px;
  background-size: 100%;
}

.backgroundCircle2 {
  background-image: url('../../../../../../assets/images/homepage/cover/circle.svg');
  background-repeat: no-repeat;
  background-position: top 335px center;
  background-size: 100%;
  max-width: 1500px;
  margin: 0 auto;
}

@media only screen and (max-width: 1388px) {
  .backgroundCircle {
    background-position: top -180px left 235px;
  }
}

@media only screen and (max-width: 1024px) {
  .backgroundCircle {
    background-position: top -130px left 135px;
  }
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.cover-height {
  position: relative;
  height: calc(100vh - 100px);
}

@media (max-width: 1024px) {
  .cover-height {
    height: calc(100vh - 80px);
  }
}

@media (max-width: 992px) {
  .cover-height {
    height: calc(100vh - 80px);
  }
}